<template>
  <div class="about-us">
    <div id="Content">
      <div class="content-inner">
        <div class="row features" id="guestlist">
          <div class="col full center">
            <h2 class="buyer">
              GUEST LIST & VIP
            </h2>
          </div>
          <Card class="col half">
            <ul>
              <li v-for="(feature, index) in guestlist_vip" :key="index">
                {{ feature }}
              </li>
            </ul>
          </Card>
          <Card class="col half">
            <ul>
              <li v-for="(feature, index) in guestlist_vip2" :key="index">
                {{ feature }}
              </li>
            </ul>
          </Card>
        </div>
        <div class="row">
          <div class="col full"><h2>PRICING PLANS</h2></div>
        </div>
      </div>
      <div class="pricing-plan-container">
        <div
          v-for="(pricing, index) in pricingPlans"
          :key="index"
          :class="{ 'most-popular': pricing.popular }"
        >
          <p v-if="pricing.popular">Most Popular</p>

          <subscription-tile :subscription="pricing" noButtons />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubscriptionTile from "../..//components/brand/SubscriptionTile.vue";

export default {
  name: "guest-list",
  components: { SubscriptionTile },
  data() {
    return {
      guestlist_vip: [
        "International payment processing",
        "Public and private events",
        "Unlimited events",
        "Guest lists",
        "Pre-purchase bottles",
        "Pre-purchase food",
        "Pre-purchase sections",
        "Reserved sections",
        "Recurring events for easy setup",
        "Birthday integrations",
        "Offer as an up-sell on ticketed events",
        "Saved menu flow",
        "Expected vs Actual attendees",
        "Tie VIP to promoters and staff for credit",
        "Rankings to identify MVP VIPs",
        "Denied list",
        "Wait list",
        "White labelling",
        "Manual or auto-approvals",
        "AI based segmentation",
        "Use our email CRM"
      ],
      guestlist_vip2: [
        "System notifications",
        "Easy check-in",
        "Email automations",
        "Embed the form anywhere",
        "Financial tracking",
        "Control group sizes",
        "Control access times",
        "Apply auto-grats",
        "Put your rules front and center",
        "Create packages",
        "Leave internal notes",
        "Edit reservations on the fly",
        "Track ALL data",
        "CSV downloads",
        "Real time data like check-ins & purchase summaries",
        "3rd party processors",
        "Integration with PatronScan coming soon",
        "& MORE FEATURES RELEASED REGULARLY!"
      ],
      pricingPlans: [
        {
          bookings: "5,000",
          price: "74.99",
          id: "1",

          trial: true,
          credits: 5000
        },
        {
          bookings: "10,000",
          price: "124.99",
          id: "2",

          popular: true,
          credits: 10000
        },
        {
          bookings: "25,000",
          price: "199.99",
          id: "3",

          credits: 25000
        },
        {
          bookings: "25,000 +",
          price: "299.99",
          id: "4",
          demo: true,
          stripeId: "",
          credits: 25000
        }
      ]
    };
  },
  methods: {}
};
</script>

<style lang="less" scoped>
.content-inner {
  padding: 0 !important;
  h1 {
    font-size: 24px;
    color: white;
  }

  h2 {
    margin: 32px 0;
  }
  h1,
  h2 {
    color: white;
  }

  h3,
  h4 {
    color: var(--primary-green);
  }

  iframe {
    border-radius: 10px;
  }

  .row.header {
    h1 {
      margin-bottom: 0;

      &.incredible {
        background: linear-gradient(
          270deg,
          rgba(68, 182, 120, 1) 0%,
          rgba(43, 172, 217, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0;
        margin-bottom: 20px;
        font-size: 32px;
      }
    }
  }

  .footer-text {
    p {
      font-size: 20px;
      text-align: center;
      span {
        font-weight: bold;
        background: linear-gradient(
          270deg,
          rgba(68, 182, 120, 1) 0%,
          rgba(43, 172, 217, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .row.features-link {
    margin: 16px 0 !important;

    h2 {
      margin: 8px 0;
      cursor: pointer;
    }
  }
  .row.features {
    h2 {
      margin: 0;
      margin-bottom: 32px;
    }
    ul {
      padding-inline-start: 24px;
    }
    .card {
      width: 100%;
      padding: 16px !important;
    }
  }
  .row.calculator {
    h4 {
      margin: 16px 0;
      margin-top: 0;
    }
  }

  .formulate-input.avg-fees {
    ::v-deep input:disabled {
      opacity: 0.8;
    }
    ::v-deep input {
      font-weight: bold;
      color: var(--primary-green);
    }
  }

  .formulate-input.ind-avg {
    ::v-deep input:disabled {
      opacity: 0.8;
    }
    ::v-deep input {
      font-weight: bold;
      color: red;
    }
  }

  .row.divider-text {
    margin: 16px;
  }

  h2 {
    color: #ffffff;
    text-align: center;
  }

  .individuals-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

@media screen and (max-width: 600px) {
  h2 {
    font-size: 22px;
  }
  .row {
    .col:not(:first-child) {
      &.half,
      &.quarter,
      &.quarterx3 {
        margin: 16px 0;
      }
    }
    .col.half,
    .col.quarter,
    .col.quarterx3 {
      width: 100% !important;
    }
  }
}

.pricing-plan-container {
  justify-content: space-evenly;
  align-items: center;
  max-width: 1000px;
  width: 90%;
  padding: 0 0 8px 0;
  margin: auto;
  display: flex;
  column-gap: 24px;
  row-gap: 24px;
  flex-wrap: wrap;

  .most-popular {
    background: var(--primary-green);
    padding: 1px;
    border-radius: 8px;

    > p {
      margin: 8px 0;
      font-size: 24px;
      text-align: center;
      color: black;
      font-weight: bold;
    }
    .plan {
      background: #2a2d33;

      ::v-deep .formulate-input {
        button {
          background: white;
        }
      }
    }
  }
}

.row.contact-us {
  .formulate-input {
    width: fit-content;
  }
}
</style>